import request from '@/utils/request'

// 获取开放实验列表
// export function getopenexperimentlist(params) {
//     return request({
//         url: '/portal/open-experiment/get-list',
//         method: 'GET',
//         params
//     })
// }

// 获取开放实验实验用房列表
export function getopenexperimentlabroomlist(params) {
    return request({
        url: '/portal/open-experiment/get-lab-room-list',
        method: 'GET',
        params
    })
}

// 获取开放实验实验用房周次列表
export function getExperimentLabRoomWeekList(params) {
    return request({
        url: '/portal/open-experiment/get-lab-room-week-list',
        method: 'GET',
        params
    })
}

// 获取开放实验规则设置详情
export function getExperimentRuleInfo(params) {
    return request({
        url: '/portal/open-experiment/get-rule-info',
        method: 'GET',
        params
    })
}

