import request from '@/utils/request'


//获取列表
export function getlablist(params) {
  return request({
    url: '/portal/lab/get-list',
    method: 'GET',
    params
  })
}
//获取实验室使用排行
export function getlabrank(params) {
    return request({
      url: '/portal/lab/lab-rank',
      method: 'GET',
      params
    })
  }