import request from '@/utils/request'

//开放实验取消预约
export function cancelopenexperimentbook(data) {
    return request({
        url: '/portal/open-experiment-book/cancel',
        method: 'post',
        data
    })
}

//开放实验预约
export function openexperimentbook(data) {
    return request({
        url: '/portal/open-experiment-book/book',
        method: 'post',
        data
    })
}

//获取开放实验的课节列表
export function getlessonlist(params) {
    return request({
      url: '/portal/open-experiment-book/get-lesson-list',
      method: 'GET',
      params
    })
  }

  //获取我预约的开放实验
export function getmybook(params) {
    return request({
      url: '/portal/open-experiment-book/get-my-book',
      method: 'GET',
      params
    })
  }