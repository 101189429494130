/**
 * 日期格式化(Format Data)
 * @param {String} dateTime 具体日期或时间戳(timeStamp)
 * @param {String} format 格式
 * @returns {Number} format
 */
function formatDate(dateTime, format) {
	if (!time) {
		time = new Date()
	}; // 默认时间 当天
	if (!fmt) {
		fmt = "YYYY-MM-DD hh:mm:ss"
	}; // 默认时间格式 年-月-日 时：分：秒

	const date = new Date(time)
	if (/(Y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	const o = {
		"Q+": Math.floor((date.getMonth() + 3) / 3), // 季度
		'M+': date.getMonth() + 1, // 月份
		'D+': date.getDate(), // 日期
		'h+': date.getHours(), // 小时
		'm+': date.getMinutes(), // 分钟
		's+': date.getSeconds(), // 秒钟
		"ms": date.getMilliseconds(), // 毫秒
	};
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + '';
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
		}
	}
	return fmt;
};
// 补"0"
function padLeftZero(str) {
	return ('00' + str).substr(str.length);
};

/**
 * 日期格式规整
 * @param {String} date 具体日期或时间戳(timeStamp)
 * @returns {String} format 格式 20080101=>2008-01-01
 */
function convertDate(date) {
	const dateString = date ? date.toString() : "";
	// 假设日期格式为YYYYMMDD
	const year = dateString.slice(0, 4);
	const month = dateString.slice(4, 6);
	const day = dateString.slice(6, 8);
	// 转换为YYYY-MM-DD格式
	return year +"-"+ month  +"-"+ day	
}

/**
 * 获取学期周次(Calculate Semester Weeks)
 * @param {String} startDate 开始日期
 * @param {String} endDate 结束日期
 * @returns {Number} weeks 
 */
function getWeeksBetweenDates(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let weeks = 0;
  while (start < end) {
      start.setDate(start.getDate() + 7);
      weeks++;
  }
  return weeks;
}

/**
 * 获取当前日期所在(学期)周次(Calculate Current Date In Semester Weeks)
 * @param {String} currentDate 当前日期
 * @param {String} startDate 开始日期
 * @param {String} endDate 结束日期
 * @returns {Number} weeks 
 */
function getCurrentCycle(startDate, endDate, currentDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const current = new Date(currentDate);
  let cycle = 1;
  // 确保日期在范围内
  if (current < start || current > end) {
      throw new Error('当前日期不在起止日期范围内');
  }
  // 通过比较周数确定周期
  while (start < current) {
      start.setDate(start.getDate() + 7);
      cycle++;
  }
  return cycle;
}


/* 导出方法函数 */
export {formatDate, convertDate, getWeeksBetweenDates, getCurrentCycle }